import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { USER_LOGGIN, USER_PROFILE, CLEAR_USER_PROFILE, TABLEAU_SESSION_CREATED, USER_PREFERENCE, UPDATE_USER_PREFERENCE } from '../actionTypes';
import APIManager from '../../utilities/api-manager';
import spgVars from '../../utilities/spg-variables';
import { get, getConfig } from '../../helpers';

const actionCreator = actionCreatorFactory();

const toggleLogInStatus = actionCreator<boolean>(USER_LOGGIN);

const spgVariables = spgVars();
const Window = !spgVariables.IS_SERVER_REQUEST ? window as AnonymousObject : { dataLayer: [] };
if (Window) Window.dataLayer = Window.dataLayer || [];

const toggleSessionCreate = actionCreator<boolean>(TABLEAU_SESSION_CREATED);

const actionCreatorAsync = asyncFactory<UserStoreState>(actionCreator);

function setGTMdatalayer(userInfo: UserInfo) {
    if (Window) {
        Window.dataLayer.push({
            KeyOnlineUser: userInfo.KeyOnlineUser,
            SubscriberAccount: userInfo.SubscriberAccount,
            CompanyType: userInfo.CompanyType,
            PrimaryJobFunction: userInfo.PrimaryJobFunction,
            UserWebpartProfile: userInfo.UserWebpartProfile
        });
    }
}

const addRemoveBookmarks = (key: string, name: string, paramsList: BookmarkParams[], bookmarks: Bookmarks) => {
    paramsList.forEach(params => {
        const { action, type, bookmark } = params;
        if (action === 'add') {
            if (!(type in bookmarks)) bookmarks[type as keyof Bookmarks] = [];
            // verify is already added to bookmarks by other browser
            if (type === 'datasets' && bookmarks.datasets.filter(d => d.keyId === params.bookmark.keyId).length === 0) bookmarks.datasets.push(bookmark);
            if (type === 'solutions' && bookmarks.solutions.filter(d => d.keyId === params.bookmark.keyId).length === 0) bookmarks.solutions.push(bookmark);
            if (type === 'blueprints' && bookmarks.blueprints.filter(d => d.keyId === params.bookmark.keyId).length === 0) bookmarks.blueprints.push(bookmark);
            if (
                (type === 'queries' && !bookmarks.queries) ||
                (type === 'queries' && bookmarks.queries && bookmarks.queries.filter(d => d.keyId === params.bookmark.keyId).length === 0)
            ) {
                if (bookmarks.queries === undefined) bookmarks.queries = [];
                bookmarks.queries.push(bookmark);
            }
        } else if (action === 'remove') {
            if (type === 'datasets') bookmarks.datasets = bookmarks.datasets.filter(d => d.keyId !== bookmark.keyId);
            if (type === 'solutions') bookmarks.solutions = bookmarks.solutions.filter(d => d.keyId !== bookmark.keyId);
            if (type === 'blueprints') bookmarks.blueprints = bookmarks.blueprints.filter(d => d.keyId !== bookmark.keyId);
            if (type === 'queries') bookmarks.queries = bookmarks.queries.filter(d => d.keyId !== bookmark.keyId);
        }
    });
    return APIManager.updateUserPreferance(key, name, bookmarks).then(createResponse => {
        // update redux back with the changes
        const preferanceKey = createResponse.data && createResponse.data.Key ? createResponse.data.Key : key;
        const preferanceName = createResponse.data && createResponse.data.Name ? createResponse.data.Name : name;
        paramsList.forEach(params => {
            if (params.onSuccess) {
                params.onSuccess();
            }
        });
        return { key: preferanceKey, name: preferanceName, bookMarks: bookmarks, bookmarksLoaded: true };
    });
};

const fetchUserPreference = actionCreatorAsync<null, BookmarkPreference>(USER_PREFERENCE, (params, dispatch, getState) => {
    const state = getState();
    const bookmarkPreference = get(state, 'user.bookmarkPreference');

    const key = getConfig('common.keyBookmark');
    return APIManager.getUserPreferences().then(userpreferances => {
        const bookMarkPreferance = userpreferances.data.value.find(userpref => userpref.Key === `UserPreferences_${key}`);
        if (bookMarkPreferance) {
            return APIManager.getBookMarkUserPreference(bookMarkPreferance.Key).then(response => {
                return {
                    key: bookMarkPreferance.Key,
                    name: response.data.Name,
                    bookMarks: JSON.parse(response.data.RequestJson),
                    bookmarksLoaded: true
                };
            });
        }
        return bookmarkPreference;
    });
});

const updateUserPreference = actionCreatorAsync<BookmarkParams[], BookmarkPreference>(UPDATE_USER_PREFERENCE, (params, dispatch, getState) => {
    const state = getState();
    const bookmarkPreference = get(state, 'user.bookmarkPreference', {});
    const { key, name } = bookmarkPreference;
    // get latest bookmarks before update it
    if (key && name) {
        return APIManager.getBookMarkUserPreference(key).then(response => {
            const bookmarks: Bookmarks = JSON.parse(response.data.RequestJson);
            return addRemoveBookmarks(key, name, params, bookmarks);
        });
    }

    // bookmark not exist for the user so, add blank and then update it;
    const bookmarks: Bookmarks = { datasets: [], solutions: [], queries: [], blueprints: [] };
    return addRemoveBookmarks(key, name, params, bookmarks);
});

// get the intital datasets with only limited properties
const fetchUserProfile = actionCreatorAsync<UserProfileParams, { userInfo: UserInfo; profileItems: UserProfileData }>(
    USER_PROFILE,
    (params, dispatch, getState) => {
        const state = getState();
        const user = get(state, 'user');
        const { refreshUserInfo, onSuccess } = params;
        if (user.isLoggedIn && (!user.userInfo || refreshUserInfo)) {
            if (refreshUserInfo) {
                return APIManager.invalidateUserInfo().then(() => {
                    return APIManager.getUserInfo().then(response => {
                        if (response) {
                            if (onSuccess) {
                                onSuccess(response.data);
                            }
                            setGTMdatalayer(response.data);
                            const { Country, Email } = response.data;
                            return APIManager.getAllProfilePageItems(Country, Email).then(profileItems => {
                                const { CompanyTypeList, rolesList, RegistrationFormatItems } = profileItems.data;
                                return { ...response.data, ProfileItems: { CompanyTypeList, rolesList, RegistrationFormatItems } };
                            });
                        }
                        return user;
                    });
                });
            }
            return APIManager.getUserInfo().then(response => {
                if (response) {
                    const { Country, Email } = response.data;
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    setGTMdatalayer(response.data);
                    return APIManager.getAllProfilePageItems(Country, Email).then(profileItems => {
                        const { CompanyTypeList, rolesList, RegistrationFormatItems } = profileItems.data;
                        return { ...response.data, ProfileItems: { CompanyTypeList, rolesList, RegistrationFormatItems } };
                    });
                }
                return user;
            });
        }

        if (onSuccess) {
            onSuccess(user.userInfo);
        }
        if (user.userInfo) setGTMdatalayer(user.userInfo);

        return user.userInfo;
    }
);

const clearUserProfile = actionCreator(CLEAR_USER_PROFILE);

export { toggleLogInStatus, fetchUserProfile, clearUserProfile, toggleSessionCreate, fetchUserPreference, updateUserPreference };
