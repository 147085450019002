import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import pRetry from 'p-retry';
import GenerateHeaders from './generate-api-headers';
import { getText, getConfig, searchFilterBuilder } from '../../helpers';
import { ErrorManager } from '../error-manager';
import SPGVariables from '../spg-variables';
import parseQuery from '../parse-query';

const spgVariables = SPGVariables();
const pRetryOptions = getConfig('utilities.pRetryOptions');
const urls = getConfig('urls');
const commonEmailTemplate = getConfig('utilities.commonEmailTemplate');
const multipartBoundary = 'batch_fc11b0cc-7b4c-49ac-a1bb-ec1fb3368e51r';

const ENDPOINT_DATASETS = 'MarketDatasets';
const ENDPOINT_SOLUTIONS = 'MarketSolutions';
const ENDPOINT_S3DOCUMENTFILE = 'DocsFileVersions';

const solutionInitialProperties = [
    'KeyPlatformService',
    'MktplaceDateAdded',
    'MktplaceSolutionName',
    'MktplaceSolutionDesc',
    'MktplaceSolutionCurrentVerDate',
    'FormOrderHomepageSolution'
];

const solutionInitialExpand = [
    'MarketSolutionProvider',
    'PlatformService',
    'MarketSolutionSupportDocs($select=FormOrder;$filter=(FormOrder+eq+1);$expand=MarketPlaceSupportDoc($select=MarketPlaceSupportDocument,WebSiteURL))'
];

const solutionAdditionalProperties = [
    'MktplaceSolutionDescLong',
    'Marketplacetrialbuttontitle',
    'MktplaceSolutionInstallReq',
    'MktplaceSolutionSupportEmail',
    'MktplaceSolutionUseCase',
    'MktplaceSolutionUserBenefits',
    'MktplaceSolutionCurrentVerNum',
    'MktplaceSolutionDefaultImage',
    'EstimatedFileSize',
    'MktplaceSolutionReleaseNote',
    'MktplaceSolutionReleaseNoteURL',
    'VidyardVideoID',
    'MktplaceSolutionDocURL',
    'MarketplaceSolutionNoteTitle',
    'MarketplaceSolutionNote',
    'ActiveMarketplaceSolutionNote',
    'MarketplaceSolutionDemoID',
    'MSADataLink',
    'MSADataFilePath'
];

const solutionAdditionalExpand = [
    'MarketServiceInputFileTypes($expand=MarketplaceSolutionFileType)',
    'MarketServiceOutFileTypes($expand=MarketplaceSolutionFileType)',
    'MarketSolutionRelLinks',
    'MarketSolutionSupportDocs($select=FormOrder;$expand=MarketPlaceSupportDoc($select=MarketPlaceSupportDocument,WebSiteURL))',
    'PlatformService($select=KeyPlatformService;$expand=MarketSolutionLogos($select=OnlineImageFilePath,FormOrder))',
    'MarketplaceMSATooltip($select=MarketplaceMSATooltipText)'
];

const userProfileProperties = [
    'FirstName',
    'LastName',
    'Title',
    'Email',
    'KeyOnlineUser',
    'PrimaryJobFunction',
    'KeyCurrency',
    'Phone',
    'Street',
    'Street2',
    'City',
    'State',
    'PostalCode',
    'Country',
    'ForeignProvince',
    'ForeignZipCode',
    'ForeignPhone',
    'KeyLanguageData',
    'Culture',
    'IndustryNamesForHeader',
    'ContentPreferences',
    'MSTimeZoneID',
    'TimeZoneAbbreviation',
    'UTCOffset',
    'InternetLastLoginDate',
    'InstnName',
    'SiteDepartment',
    'KeySubscriberAccountOverride',
    'SubscriberAccount',
    'CompanyType',
    'UserWebpartProfile',
    'KeyOnlineUserCompanyType',
    'KeyOnlineUserCompanyRole',
    'InternalUser',
    'KeyWebpartProfile',
    'KeyOnlineUserCompanyDepartment',
    'TimeZoneUSName'
];

const datasetSolutionFields = [
    'Name',
    'Name_en',
    'Description_en',
    'MarketplaceLongDescription_en',
    'MarketplaceDataProvider_en',
    'MarketplaceDataProviderDesc_en',
    'isType',
    'MarketplaceDatasetAdded',
    'MktPlaceDataSetEnhancementDate',
    'KeyMarketplaceDataset',
    'KeyMarketplaceDatasetBundle',
    'ThumbnailImageFilePath',
    'keyplatformservice'
];

const bluePrintFields = [
    'Name',
    'Name_en',
    'Description_en',
    'MarketplaceLongDescription_en',
    'MarketplaceDataProvider_en',
    'MarketplaceDataProviderDesc_en',
    'isType',
    'MarketplaceDatasetAdded',
    'MktPlaceDataSetEnhancementDate',
    'KeyMarketplaceDataset',
    'KeyMarketplaceDatasetBundle',
    'ThumbnailImageFilePath',
    'keyplatformservice',
    'KeyMarketplaceBlueprint'
];

const queryLibraryFields = [
    'Name_en',
    'Description_en',
    'MarketplaceDatasetPackage_en',
    'AssociatedDataset_en',
    'AssociatedBundle_en',
    'KeyMarketplaceQuery',
    'MktPlaceDataSetEnhancementDate',
    'WebsiteURL',
    'MarketplaceQueryType',
    'DatasetID',
    'MarketplaceDatasetTable'
];

// TODO USE THIS AGAIN
const solutionFullProperties = solutionInitialProperties.concat(solutionAdditionalProperties);
const solutionFullExpand = solutionInitialExpand.concat(solutionAdditionalExpand);

axios.defaults.baseURL = spgVariables.APIBASE;
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = `multipart/mixed;boundary=${multipartBoundary}`;
axios.defaults.timeout = getConfig('utilities.requestTimeout');

// if not running from server
if (!spgVariables.IS_SERVER_REQUEST) {
    import('../security').then(security => {
        security.default.setOAuthRequestInterceptor(axios);
    });
}

const fetchingDataError = 'Data Loading Error!';

function handleError(error: AxiosError, parameters?: AnonymousObject, onError?: () => void) {
    if (!axios.isCancel(error)) {
        ErrorManager.apiError(error, parameters);
    }
    if (onError) onError();
    return Promise.reject(error);
}

function generateOdataClientHeaders(url: string) {
    if (url.indexOf('http') > -1) {
        return GenerateHeaders(url);
    }
    return GenerateHeaders(`${spgVariables.APIBASE}/${url}`);
}

function GetAllDataFromUrls<T>(serviceName: string, requestUrls: string[]) {
    return pRetry(
        () => axios.all(
            requestUrls.map(x => axios.request({
                url: x,
                method: 'GET',
                headers: generateOdataClientHeaders(x),
                withCredentials: false
            }))
        ),
        pRetryOptions
    )
        .then(
            axios.spread((...responses) => {
                let items = [];
                items = responses.map(x => {
                    if (x && x.data && x.data.error) {
                        return [];
                    }
                    return x.data;
                });
                return items;
            })
        )
        .catch((error: AxiosError) => {
            const parameters = {
                ServiceName: serviceName,
                RequestUri: requestUrls.join(', '),
                error,
                msg: fetchingDataError
            };
            return handleError(error, parameters);
        });
}

/* eslint-disable */
if (process.env.REACT_APP_SPOOF_API === 'true') {
    import('./spoofed-responses').then(spoofResponses => {
        spoofResponses.default(axios, ENDPOINT_DATASETS, ENDPOINT_SOLUTIONS, ENDPOINT_S3DOCUMENTFILE, urls.mi.odata.public);
    });
}
/* eslint-enable */

let APIToken: CancelTokenSource | null = null;

const apis = {
    getDataCatalog: () => {
        const apiLangcode = getText('apiLangCode');
        const isDefaultLanguage = getText('isDefaultLanguage');
        const requestUris = [];
        let url = `${urls.api.cms.datasetCards}?dataLang=${apiLangcode}`;
        requestUris.push(url);

        url = `${urls.api.cms.datasetBundleCards}?dataLang=${apiLangcode}`;
        requestUris.push(url);

        if (!isDefaultLanguage) {
            url = urls.api.cms.datasetNames;
            requestUris.push(url);

            url = urls.api.cms.datasetBundleNames;
            requestUris.push(url);
        }

        return GetAllDataFromUrls<APIDataBundleCards & APIDatasets>('CMS', requestUris);
    },
    logUsage: (query: LogPageAccessRequest) => {
        return new Promise(resolve => {
            return axios
                .request({
                    url: urls.mi.logging.logPageAccess,
                    method: 'POST',
                    headers: generateOdataClientHeaders(urls.mi.logging.logPageAccess),
                    withCredentials: false,
                    data: query
                })
                .then(() => resolve(true))
                .catch((error: AxiosError) => {
                    console.error('POST logUsage error');
                    // const parameters = {
                    //     ServiceName: 'LoggingService',
                    //     RequestUri: urls.mi.logging.logPageAccess,
                    //     error,
                    //     msg: 'Usage logging error'
                    // };
                    return resolve(null);
                });
        });
    },

    search: (query: string, filters?: string[], advFilters?: string[], queryFilter?: string) => {
        if (APIToken) APIToken.cancel('Search canceled due to new request.');
        const localeCode = getText('langCode');
        let fieldsToInclude = [];

        APIToken = axios.CancelToken.source();
        const filterList = searchFilterBuilder(filters || []);
        if (advFilters && advFilters.indexOf('isType:Query') > -1) {
            filterList.push({ terms: { isType: ['Query'] } });
            fieldsToInclude = queryLibraryFields;
        } else if (advFilters && advFilters.indexOf('isType:Dataset OR isType:Bundle') > -1) {
            filterList.push({ terms: { isType: ['Dataset', 'Bundle'] } });
            filterList.push({ terms: { ActiveMPDatasetCoverage: [1] } });
            fieldsToInclude = datasetSolutionFields;
        } else if (advFilters && advFilters.indexOf('isType:Solution') > -1) {
            filterList.push({ terms: { isType: ['Solution'] } });
            fieldsToInclude = datasetSolutionFields;
        } else if (advFilters && advFilters.indexOf('isType:Blueprint') > -1) {
            filterList.push({ terms: { isType: ['Blueprint'] } });
            fieldsToInclude = bluePrintFields;
        } else {
            // filterList.push({ terms: { isType: ['Dataset', 'Bundle', 'Solution'] } });
            filterList.push({ terms: { isType: ['Dataset', 'Bundle', 'Solution', 'Blueprint'] } });
            if (queryFilter !== undefined) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                filterList.push({ query_string: { query: queryFilter } });
            } else {
                // eslint-disable-next-line @typescript-eslint/camelcase
                // filterList.push({ query_string: { query: 'ActiveMPDatasetCoverage: (1) OR isType: (Solution)' } });
                // eslint-disable-next-line @typescript-eslint/camelcase
                filterList.push({ query_string: { query: 'ActiveMPDatasetCoverage: (1) OR isType: (Solution) OR isType: (Blueprint)' } }); // working
            }
            // fieldsToInclude = datasetSolutionFields;
            fieldsToInclude = bluePrintFields;
        }

        const replaceCode = localeCode === 'en' ? '' : `_${localeCode}`;
        fieldsToInclude = fieldsToInclude.map(field => {
            // replace _en with selected language code
            return field.replace('_en', replaceCode);
        });

        const data = {
            id: 'mp-all-search',
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                search_text: query,
                mode: 'and',
                limit: 1000,
                filters: filterList,
                // eslint-disable-next-line @typescript-eslint/camelcase
                fields_to_include: fieldsToInclude,
                // eslint-disable-next-line @typescript-eslint/camelcase
                fields_to_exclude: []
            }
        };
        const headers = generateOdataClientHeaders(urls.api.search);
        return (
            axios
                .request<SearchDatasetResult>({
                    url: urls.api.search,
                    method: 'POST',
                    headers,
                    withCredentials: false,
                    data,
                    cancelToken: APIToken ? APIToken.token : undefined
                })
                // .then(response => {
                //     axios.post(urls.api.search, data, { headers: { 'Content-security-policy': 'default-src "self"' } });
                //     console.log('response', response);
                // })
                .catch((error: AxiosError) => handleError(error, { msg: 'Searching Error!' }))
        );
    },
    getSolutions: () => {
        const apiLangcode = getText('apiLangCode');
        const isDefaultLanguage = getText('isDefaultLanguage');
        const requestUris = [];
        let url = `${urls.api.cms.solutionCatalog}?solutionLang=${apiLangcode}`;
        requestUris.push(url);
        if (!isDefaultLanguage) {
            url = urls.api.cms.solutionNames;
            requestUris.push(url);
        }
        return GetAllDataFromUrls<APISolution[]>('CMS', requestUris);
    },
    getSolutionById: (id: string) => {
        const apiLangcode = getText('apiLangCode');
        const query = `${ENDPOINT_SOLUTIONS}(${id})?$select=${solutionFullProperties.join(',')}&$expand=${solutionFullExpand.join(
            ','
        )}&clear=${Date.now()}&datasetLang=${apiLangcode}`;
        return axios
            .get<APISolution>(urls.mi.odata.public + query, { headers: generateOdataClientHeaders(urls.mi.odata.public + query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'OdataService',
                    RequestUri: urls.mi.odata.public + query,
                    error,
                    msg: 'Error! Can not load solution data'
                };
                return handleError(error, parameters);
            });
    },
    getSolutionDetailsById: (id: string) => {
        const apiLangcode = getText('apiLangCode');
        const query = `${urls.api.cms.solutionProfileDetails}?id=${id}&dataLang=${apiLangcode}`;
        return axios
            .get<APISolution>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'Error! Can not load solution details.'
                };
                return handleError(error, parameters);
            });
    },
    getBatchDataSetDetailsById: (id: string) => {
        const apiLangcode = getText('apiLangCode');
        const requestUris = [
            `${urls.api.cms.datasetProfileDetails}?id=${id}&dataLang=${apiLangcode}`,
            `${urls.api.cms.datasetProfileIndustry}?id=${id}&dataLang=${apiLangcode}`
        ];
        return GetAllDataFromUrls<APIDataset>('CMS', requestUris);
    },
    // TODO: unused method?
    // getS3DocByKey: (key: string, isSecure?: boolean) => {
    //     const query = `${ENDPOINT_S3DOCUMENTFILE}(${key})/$value`;
    //     return pRetry(() => {
    //         if (isSecure) {
    //             return axios.get<string>(urls.mi.odata.general + query, { headers: generateOdataClientHeaders(urls.mi.odata.general + query) });
    //         }
    //         return axios.get<string>(urls.mi.odata.public + query, { headers: generateOdataClientHeaders(urls.mi.odata.public + query) });
    //     }, pRetryOptions).catch((error: AxiosError) => {
    //         const parameters = {
    //             ServiceName: 'OdataService',
    //             RequestUri: isSecure ? urls.mi.odata.general + query : urls.mi.odata.public + query,
    //             error
    //         };
    //         return handleError(error, parameters);
    //     });
    // },
    getS3HtmlDocByKey: (key: string) => {
        const query = `${urls.api.cms.htmlDocs}?id=${key}`;
        return pRetry(() => {
            return axios.get<string>(query, { headers: generateOdataClientHeaders(query) });
        }, pRetryOptions).catch((error: AxiosError) => {
            const parameters = {
                ServiceName: 'CMS',
                RequestUri: query,
                error,
                msg: fetchingDataError
            };
            return handleError(error, parameters);
        });
    },
    getDownloadLinkByKey: (id: string, isBundle: boolean) => {
        const query = `${
            isBundle ? `${urls.api.cms.bundleProfileSampleDataKeys}?id=${id}` : `${urls.api.cms.datasetProfileSampleDataKeys}?id=${id}`
        }`;
        return axios
            .get<DatasetDownloadLink>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'Download Link loading Error!'
                };
                return handleError(error, parameters);
            });
    },
    getSolutionsAccessLinksbyKey: (key: string) => {
        const query = `${urls.api.cms.solutionAccessLink}?id=${key}`;
        return axios
            .get<ProductAccessLink[]>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => handleError(error, { msg: 'Solution Access Links loading Error!' }));
    },
    getBlueprintAccessLinksbyKey: (key: string) => {
        const query = `${urls.api.cms.blueprintAccessLink}?id=${key}`;
        return axios
            .get<ProductAccessLink[]>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => handleError(error, { msg: 'Blueprint Access Links loading Error!' }));
    },
    getDatasetAccessLinksbyKey: (type: string, key: string) => {
        const endpoint = type === 'dataset' ? urls.api.cms.datasetProfileAccessDataLinks : urls.api.cms.bundleProfileAccessDataLinks;
        const query = `${endpoint}?id=${key}`;
        return axios
            .get<ProductAccessLink[]>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => handleError(error, { msg: 'Dataset Access Links loading Error!' }));
    },
    getBatchFilters: () => {
        const apiLangcode = getText('apiLangCode');
        const requestUris = [];
        let url = `${urls.api.cms.mIIndustryTree}?dataLang=${apiLangcode}`;
        requestUris.push(url);
        url = `${urls.api.cms.lookupSPGDestPlatforms}?dataLang=${apiLangcode}`;
        requestUris.push(url);
        url = `${urls.api.cms.marketPlaceSearchTags}?dataLang=${apiLangcode}`;
        requestUris.push(url);
        url = `${urls.api.cms.geographyTree}?dataLang=${apiLangcode}`;
        requestUris.push(url);
        url = `${urls.api.cms.countries}`;
        requestUris.push(url);
        url = `${urls.api.cms.states}`;
        requestUris.push(url);
        url = `${urls.api.cms.datasetFeatured}`;
        requestUris.push(url);
        url = `${urls.api.cms.datasetAlternative}`;
        requestUris.push(url);
        url = `${urls.api.cms.vendors}`;
        requestUris.push(url);
        url = `${urls.api.cms.datasetExtract}`;
        requestUris.push(url);
        url = `${urls.api.cms.featuredBlueprints}`;
        requestUris.push(url);
        url = `${urls.api.cms.marketPlaceQueryTypes}`;
        requestUris.push(url);
        url = `${urls.api.cms.qlDatasetName}`;
        requestUris.push(url);
        return GetAllDataFromUrls<APIFilters>('CMS', requestUris);
    },
    getBundleDataById: (id: string) => {
        const apiLangcode = getText('apiLangCode');
        const requestUris = [
            `${urls.api.cms.bundleProfileDetails}?id=${id}&dataLang=${apiLangcode}`,
            `${urls.api.cms.bundleProfileIndustry}?id=${id}&dataLang=${apiLangcode}`,
            `${urls.api.cms.bundleProfileRelateds}?id=${id}&dataLang=${apiLangcode}`
        ];
        return GetAllDataFromUrls<APIDataBundle>('CMS', requestUris);
    },
    getFAQs: () => {
        const apiLangcode = getText('apiLangCode');
        const query = `${urls.api.cms.faqs}?datasetLang=${apiLangcode}`;
        return axios
            .get<APIMarketFAQ>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'FAQs loading error'
                };
                return handleError(error, parameters);
            });
    },
    getUserInfo: () => {
        const query = `?$select=${userProfileProperties.join(',')}`;
        return pRetry(() => {
            return axios.get<UserInfo>(urls.mi.security.userProfile + query);
        }, pRetryOptions).catch((error: AxiosError) => {
            const parameters = {
                ServiceName: 'SecurityService',
                RequestUri: urls.mi.security.userProfile + query,
                error,
                msg: 'User Info loading Error! You are logged out because of security reasons.'
            };
            handleError(error, parameters);
            import('../security').then(security => {
                security.default.signOut();
            });
        });
    },
    invalidateUserInfo: () => {
        return axios.get(urls.mi.security.invalidateUserProfile);
    },
    getAllProfilePageItems: (keyCountry: string, emailDomain: string) => {
        const query = `${urls.mi.security.getRegistrationItems}?country=${keyCountry}&registeredLanguage=0&displayLanguage=0&emailDomain=${emailDomain}`;
        return axios
            .post<UserProfileData>(
                query,
                `{"country": "${keyCountry}","registeredLanguage":0,"displayLanguage":0,"emailDomain":"${emailDomain}","SignupToken":""}`
            )
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'SecurityService',
                    RequestUri: query,
                    error
                };
                return handleError(error, parameters);
            });
    },
    updateProfileInfo: (data: string, onError: () => void) => {
        const query = `${urls.mi.security.updateProfileInfo}`;
        return axios
            .post<string>(query, data, { headers: { 'content-type': 'application/json' } })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'SecurityService',
                    RequestUri: query,
                    error,
                    msg: 'Profile updating Error!'
                };
                return handleError(error, parameters, onError);
            });
    },
    updatePassword: (currentPassword: string, newPassword: string) => {
        const data = {
            userName: '',
            currentPassword,
            newPassword,
            appCode: getConfig('utilities.appCode')
        };
        return axios
            .request<UpdatePasswordResponse>({
                url: urls.mi.security.updateIDMPassword,
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                data
            })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'SecurityService',
                    RequestUri: urls.mi.security.updateIDMPassword,
                    error,
                    msg: 'Request error! Password is not updated'
                };
                return handleError(error, parameters);
            });
    },
    sendEmail: (body: string, subject: string, emailTo: string, requestStatus: (status: boolean) => void) => {
        const url = urls.mi.messaging;
        const data = {
            To: emailTo,
            Template: commonEmailTemplate,
            Subject: subject,
            UserComment: body
        };
        return axios
            .request<string>({
                url,
                method: 'POST',
                headers: { ...generateOdataClientHeaders(url), 'content-type': 'application/json' },
                data
            })
            .then(() => requestStatus(true))
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'MessageService',
                    RequestUri: url,
                    error,
                    msg: 'Email is not sent due to an error'
                };
                handleError(error, parameters);
                requestStatus(false);
            });
    },
    getResearchLinks: () => {
        return axios.get<ResearchLinks>(urls.api.crownpeak.researchLinks).catch((error: AxiosError) => {
            console.error('Research links loading error');
            // return handleError(error);
            return Promise.reject(error);
        });
    },
    getIdVariants: () => {
        return axios.get<IdVariantsData>(urls.api.config.variants, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((error: AxiosError) => {
            console.error('IdVariants loading error');
            // return handleError(error);
            return Promise.reject(error);
        });
    },
    getTableauSessionTicket: () => {
        // added random number as IE cache the token due to some reason;
        const url = `${urls.api.tableau.token}?clear=${Date.now()}`;
        return axios.get<string>(url).catch((error: AxiosError) => {
            console.error('getTableauSessionTicket error');
            // return handleError(error);
            return Promise.reject(error);
        });
    },
    getPowerBIEmbedInfo: () => {
        const url = `${urls.api.powerbi.token}`;
        return axios.get<PowerBIEmbedContent>(url).catch((error: AxiosError) => {
            console.error('getPowerBIEmbedInfo error');
            // return handleError(error);
            return Promise.reject(error);
        });
    },
    getHomepageCompanyTypes: (id: number) => {
        const query = `${urls.api.cms.homepageCompanyType}/?keyOnlineUserCompanyType=${id}`;
        return axios
            .get<HomepageCompanyType[]>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'Company types loading error'
                };
                return handleError(error, parameters);
            });
    },
    getBookMarkUserPreference(key: string) {
        const url = `${urls.mi.storage.storedObjects}('${key}')?clear=${Date.now()}`;
        return axios.get<UserPreferencesResponse>(url).catch((error: AxiosError) => {
            const parameters = {
                ServiceName: 'StorageService',
                RequestUri: url,
                error,
                msg: 'Bookmarks data loading error'
            };
            return handleError(error, parameters);
        });
    },
    updateUserPreferance(key: string, name: string, requestJson: Bookmarks) {
        const isUpdate = key !== undefined && name !== undefined;
        const url = urls.mi.storage.storedObjects + (isUpdate ? `('${key}')` : '');
        const objectName = isUpdate ? name : getConfig('common.keyBookmark');
        const data = {
            '@odata.type': '#StorageService.UserPreferences',
            Name: objectName,
            StoredObjectType: 'UserPreferences',
            RequestJson: JSON.stringify(requestJson)
        };

        return axios
            .request<UserPreferencesResponse | undefined>({
                url,
                method: isUpdate ? 'PUT' : 'POST',
                data
            })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'StorageService',
                    RequestUri: url,
                    error,
                    msg: 'Preferences are not updated due to an Error!'
                };
                return handleError(error, parameters);
            });
    },

    getUserPreferences() {
        const url = `${urls.mi.storage.storedObjects}?$filter=(StoredObjectType+eq+StorageService.StoredObjectType'7')`;
        return axios.get<UserPreferences>(url).catch((error: AxiosError) => {
            const parameters = {
                ServiceName: 'StorageService',
                RequestUri: url,
                error,
                msg: 'User Preferences data loading Error!'
            };
            return handleError(error, parameters);
        });
    },

    getDownloads() {
        const url = `${urls.api.selfService.querydownload}`;
        return axios
            .get<Downloads>(url, { headers: generateOdataClientHeaders(url) })
            .catch((error: AxiosError) => {
                console.error('getDownloads error');
                // return handleError(error);
                return Promise.reject(error);
            });
    },

    getQueryBuilderSteps() {
        const url = `${urls.api.selfService.queryBuilderSteps}`;
        return axios
            .get<QueryBuilderStep[]>(url, { headers: generateOdataClientHeaders(url) })
            .catch((error: AxiosError) => {
                return handleError(error, { msg: 'Query builder steps loading Error!' });
            });
    },

    getAwsDownloadUrl(id: string, docType = '', selectedCompanyIdLookup = '') {
        let url = `${urls.api.selfService.awsDownloadUrl}/${id}`;
        if (docType) {
            url = `${urls.api.selfService.awsDownloadUrl}/codes/${id}/${docType}/${selectedCompanyIdLookup}`;
        }
        return axios
            .get<string>(url, { headers: generateOdataClientHeaders(url) })
            .catch((error: AxiosError) => handleError(error, { msg: 'Can not get AWS download url' }));
    },

    getPageTexts: () => {
        const apiLangcode = getText('apiLangCode');
        const query = `${urls.api.cms.pageText}?dataLang=${apiLangcode}`;
        return axios
            .get<PageSections[]>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'Error! Can not get page texts'
                };
                return handleError(error, parameters);
            });
    },

    getGitData: (contentUrl: string) => {
        const url = `${urls.api.gitContent}?contentUrl=${encodeURIComponent(contentUrl)}`;
        return axios
            .get<string>(url, { headers: generateOdataClientHeaders(url) })
            .then(res => {
                return parseQuery(res.data);
            })
            .catch((error: AxiosError) => handleError(error, { msg: 'Error! Can not get query data.' }));
    },

    submitLeadToMarketo(data: NotifyMeRequest | RATRequest | RATPlattsRequest | RATCommuniqueBaseRequest | RATVisualizationRequest) {
        const url = urls.api.createLead.marketo;
        return axios
            .request<boolean>({
                url,
                method: 'POST',
                headers: generateOdataClientHeaders(urls.api.createLead.marketo),
                withCredentials: false,
                data
            })
            .catch((error: AxiosError) => handleError(error, { msg: 'Error! Can not lead to Marketo' }));
    },

    submitLeadToCrisil(data: RATCrisilRequest) {
        const url = urls.api.createLead.crisil;
        return axios
            .request<boolean>({
                url,
                method: 'POST',
                headers: generateOdataClientHeaders(urls.api.createLead.crisil),
                withCredentials: false,
                data
            })
            .catch((error: AxiosError) => handleError(error, { msg: 'Error! Can not lead to Crisil' }));
    },

    submitLeadToEloqua(data: RATIHSMarkitRequest) {
        const url = urls.api.createLead.eloqua;
        return axios
            .request<boolean>({
                url,
                method: 'POST',
                headers: generateOdataClientHeaders(urls.api.createLead.eloqua),
                withCredentials: false,
                data
            })
            .catch((error: AxiosError) => handleError(error, { msg: 'Error! Can not lead to Eloqua' }));
    },

    submitQueryExecute(data: BuildQueryRequest) {
        const url = urls.api.selfService.saveExecute;
        // Field will be hard coded for time being except filters
        return axios.request({
            url,
            method: 'POST',
            headers: generateOdataClientHeaders(url),
            withCredentials: false,
            data
        });
    },

    submitSubQueryExecute(data: BuildSubQueryRequest) {
        const url = urls.api.selfService.saveExecuteSubQuery;
        // Field will be hard coded for time being except filters
        return axios.request({
            url,
            method: 'POST',
            headers: generateOdataClientHeaders(url),
            withCredentials: false,
            data
        });
    },

    cancelQuery(requestId: string, callbackCancelReqError: (reqId: string) => void) {
        const url = `${urls.api.selfService.cancelRequest}/${requestId}`;
        return axios.get(url, { headers: generateOdataClientHeaders(url) }).catch((error: AxiosError) => {
            if (error && error.response && error.response.status === 404) {
                callbackCancelReqError(requestId);
            } else {
                handleError(error, { msg: 'Error! Can not cancel the query.' });
            }
        });
    },

    rerunQuery(requestId: string) {
        const url = `${urls.api.selfService.rerunRequest}/${requestId}`;
        return axios.get(url, { headers: generateOdataClientHeaders(url) });
    },

    findKenshoLinkMatch(payload: AnonymousObject, accessToken: string, errorCallBack: (reqError: AxiosError) => void) {
        return axios
            .post<LinkResponse>(urls.mi.kensho.link, JSON.stringify(payload), {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` }
            })
            .catch(reqError => {
                const parameters = {
                    ServiceName: 'KenshoService',
                    RequestUri: urls.mi.kensho.link,
                    error: reqError
                };
                handleError(reqError, parameters);

                errorCallBack(reqError);
            });
    },

    getAudioFile(fileName: string) {
        return axios.get<Blob>(`${window.location.origin}${fileName}`, { responseType: 'blob' });
    },

    getReqHeaderByName(headerName: string) {
        const url = `${urls.api.reqHeader}?headerName=${headerName}`;
        return axios.get<string>(url).catch((error: AxiosError) => Promise.reject(error));
    },

    getProductFinderData(langCode: string) {
        const data = {
            id: 'product-finder-mapping',
            params: {
                // eslint-disable-next-line
                fields_to_include: [
                    'isSection',
                    'KeyMarketplaceDataset',
                    'KeyMarketplaceDatasetBundle',
                    'KeyMarketplaceRole',
                    'KeyMarketplaceWorkflow',
                    'KeyPlatformService',
                    'KeyUseCaseCompanyType',
                    `MarketplaceRoleName${langCode}`,
                    `MarketplaceWorkflowName${langCode}`,
                    `UseCaseCompanyTypeName${langCode}`
                ],
                limit: 2000
            }
        };

        const headers = generateOdataClientHeaders(urls.api.search);
        return axios
            .request<SearchDatasetResult>({
                url: urls.api.search,
                method: 'POST',
                headers,
                withCredentials: false,
                data,
                cancelToken: APIToken ? APIToken.token : undefined
            })
            .catch((error: AxiosError) => handleError(error, { msg: 'Error! Can not get product finder data' }));
    },

    getLLMAccessToken: () => {
        const url = `${urls.api.cms.accessToken}`;
        APIToken = axios.CancelToken.source();
        return axios.get<LLMAccessToken>(url, { cancelToken: APIToken ? APIToken.token : undefined }).catch((error: AxiosError) => {
            // this request should not show red toast in case of error
            console.error(error.message);
            return Promise.reject(error);
        });
    },

    getChatiqSearchById: (data: ChatiqSearchRequest, accessToken: string) => {
        const url = urls.api.llm.search;
        APIToken = axios.CancelToken.source();
        return axios
            .request({
                url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Llm-Authorization': `${accessToken}` },
                withCredentials: false,
                data,
                cancelToken: APIToken ? APIToken.token : undefined
            })
            .catch((error: AxiosError) => {
                // this request should not show red toast in case of error
                console.error(error.message);
                return Promise.reject(error);
            });
    },

    sendChatiqSearchFeedbackById: (data: ChatiqSearchFeedback, accessToken: string) => {
        const url = urls.api.llm.feedback;
        return axios
            .request({
                url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Llm-Authorization': `${accessToken}` },
                withCredentials: false,
                data
            })
            .catch((error: AxiosError) => {
                // this request should not show red toast in case of error
                console.error(error.message);
                return Promise.reject(error);
            });
    },

    getBlueprints: () => {
        const apiLangcode = getText('apiLangCode');
        const isDefaultLanguage = getText('isDefaultLanguage');
        const requestUris = [];
        let url = `${urls.api.cms.blueprintCatalog}?dataLang=${apiLangcode}`;
        requestUris.push(url);
        if (!isDefaultLanguage) {
            url = urls.api.cms.blueprintNames;
            requestUris.push(url);
        }
        return GetAllDataFromUrls<APIBlueprints>('CMS', requestUris);
    },

    getBlueprintDetailsById: (id: string) => {
        const apiLangcode = getText('apiLangCode');
        const query = `${urls.api.cms.blueprintProfileDetails}?id=${id}&dataLang=${apiLangcode}`;
        return axios
            .get<APIBlueprint>(query, { headers: generateOdataClientHeaders(query) })
            .catch((error: AxiosError) => {
                const parameters = {
                    ServiceName: 'CMS',
                    RequestUri: query,
                    error,
                    msg: 'Error! Can not get the blueprint data.'
                };
                return handleError(error, parameters);
            });
    },

    getTileNamesGt25Chars: () => {
        const url = `${urls.api.cms.tileNamesGt25Chars}`;
        return axios.get<any>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((error: AxiosError) => {
            return handleError(error);
        });
    },

    cancelApiRequest: () => {
        if (APIToken) APIToken.cancel('Search canceled.');
        APIToken = null;
    }

};

export default apis;
